import React from 'react'
import PropTypes from "prop-types"
import withLocation from "~/hoc/withLocation"
const windowGlobal = typeof window !== 'undefined' && window

const CustomQueryStringComponent = ({ search }) => {
  const { utm_campaign } = search
  windowGlobal && localStorage.setItem('affiliateCode', utm_campaign)
  return <p>Affiliate code: {utm_campaign}</p>
}

CustomQueryStringComponent.propTypes = {
  search: PropTypes.object,
}

export default withLocation(CustomQueryStringComponent)